const messages_sv = {
    blog: 'Blogg',
    'blog.latest': 'Senaste artiklarna',
    login: 'Logga in',
    related: 'Relaterad',
    'contactform.name': 'Namn',
    'contactform.phone': 'Telefon',
    'contactform.email': 'E-post',
    'contactform.message': 'Meddelande',
    'contactform.skynet_pot': 'Fyll inte detta om du är en människa:',
    'contactform.submit': 'Skicka',
    'contactform.error': 'Å nej! Det uppstod ett fel när formuläret skickades! Vänligen försök igen senare.',
    'contactform.thankyou.h': 'Tack!',
    'contactform.thankyou.p': 'Vi fick ditt meddelande och tar en titt på det snart.',
    'contactform.accept_privacy_policy':
        'Jag accepterar behandlingen av mina personuppgifter och <pp>integritetspolicyn</pp>.',
    'cookieconsent.heading': 'Information om cookies',
    'cookieconsent.content.p':
        'Vår webbplats använder cookies från första och tredje part för att kunna erbjuda den bästa möjliga användarupplevelse när du besöker oss. Vi använder oss av cookies för prestanda och analys endast ifall du accepterar dem genom att välja Acceptera. Genom att välja Avvisa nekar du användningen av cookies för prestanda och analys, men vi kan fortfarande använda nödvändiga förstapartscookies om du fortsätter att surfa på vår webbplats. <a>Här</a> finner du vår Cookie policy.',
    'cookieconsent.decline': 'Avvisa',
    'cookieconsent.accept': 'Acceptera',
}

export default messages_sv
