const messages_de = {
    blog: 'Blog',
    'blog.latest': 'Neueste Artikel',
    login: 'Anmelden',
    related: 'Verbunden',
    'contactform.name': 'Name',
    'contactform.phone': 'Telefon',
    'contactform.email': 'Email',
    'contactform.message': 'Nachricht',
    'contactform.skynet_pot': 'Füllen Sie dies nicht aus, wenn Sie ein Mensch sind:',
    'contactform.submit': 'Senden',
    'contactform.error':
        'Oh nein, beim Absenden dieses Formulars ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!',
    'contactform.thankyou.h': 'Danke!',
    'contactform.thankyou.p': 'Wir haben Ihre Nachricht erhalten und werden uns diese bald ansehen.',
    'contactform.accept_privacy_policy':
        'Ich akzeptiere die Verarbeitung meiner persönlichen Daten und die <pp>Datenschutzrichtlinie</pp>.',
    'cookieconsent.heading': 'Sie kontrollieren Ihre Daten',
    'cookieconsent.content.p':
        'Unsere Website verwendet Cookies von Erst- und Drittanbietern, die die Funktion der Website unterstützen, damit wir Ihnen die bestmögliche Benutzererfahrung bieten können. Wir verwenden analytische Cookies nur, wenn Sie dem zustimmen, indem Sie auf Akzeptieren klicken. Indem Sie auf „Ablehnen“ klicken, lehnen Sie die Verwendung von analytischen Cookies ab, aber wir können dennoch notwendige First-Party-Cookies verwenden, wenn Sie weiterhin auf unserer Website surfen. Unsere Cookie-Richtlinie finden Sie <a>hier</a>.',
    'cookieconsent.decline': 'Ablehnen',
    'cookieconsent.accept': 'Annehmen',
}

export default messages_de
