const messages_fi = {
    blog: 'Blogi',
    'blog.latest': 'Viimeisimmät artikkelit',
    login: 'Kirjaudu',
    related: 'Liittyvät',
    'contactform.name': 'Nimi',
    'contactform.phone': 'Puhelin',
    'contactform.email': 'Sähköposti',
    'contactform.message': 'Viesti',
    'contactform.skynet_pot': 'Älä täytä tätä jos olet ihminen:',
    'contactform.submit': 'Lähetä',
    'contactform.error': 'Voi ei, lomakkeen lähetyksessä tapahtui virhe! Yritä myöhemmin uudelleen.',
    'contactform.thankyou.h': 'Kiitos!',
    'contactform.thankyou.p': 'Saimme viestisi ja katsomme sitä pian.',
    'contactform.accept_privacy_policy': 'Hyväksyn henkilötietojeni käsittelyn ja <pp>tietosuojaselosteen</pp>.',
    'cookieconsent.heading': 'Tietoa sivustolla käytetyistä evästeistä',
    'cookieconsent.content.p':
        'Verkkosivustomme käyttää ensimmäisen ja kolmannen osapuolen evästeitä asiakaskokemuksen parantamiseksi. Käytämme analytiikkaevästeitä vain, jos hyväksyt ne. Mikäli kieltäydyt analytiikkaevästeistä, saatamme silti käyttää välttämättömiä ensimmäisen osapuolen evästeitä jos jatkat sivuston selaamista. Evästekäytäntömme löytyy <a>täältä</a>.',
    'cookieconsent.decline': 'Hylkää',
    'cookieconsent.accept': 'Hyväksy',
}

export default messages_fi
