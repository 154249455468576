const messages_en = {
    blog: 'Blog',
    'blog.latest': 'Latest articles',
    login: 'Login',
    related: 'Related',
    'contactform.name': 'Name',
    'contactform.phone': 'Phone',
    'contactform.email': 'Email',
    'contactform.message': 'Message',
    'contactform.skynet_pot': 'Do not fill this out if you’re human:',
    'contactform.submit': 'Send',
    'contactform.error': 'Oh no, there was an error submitting this form. Please try again later!',
    'contactform.thankyou.h': 'Thank you!',
    'contactform.thankyou.p': 'We received your message and will take a look at it soon.',
    'contactform.accept_privacy_policy': 'I accept the processing of my personal data and the <pp>privacy policy</pp>.',
    'cookieconsent.heading': 'You control your data',
    'cookieconsent.content.p':
        'Our website uses first and third party cookies that help the website function, so that we can provide you with the best possible user experience. We will only use analytical cookies if you consent to it by clicking on Accept. By clicking Reject you deny the use of analytical cookies, but we may still use necessary first party cookies if you continue browsing our website. Our Cookie policy is found <a>here</a>.',
    'cookieconsent.decline': 'Reject',
    'cookieconsent.accept': 'Accept',
}

export default messages_en
